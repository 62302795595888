
import { mapGetters } from 'vuex'
import SsrCarousel from 'vue-ssr-carousel'
// eslint-disable-next-line no-unused-vars
import ssrCarouselCss from 'vue-ssr-carousel/index.css'
export default {
  components: { SsrCarousel },
  props:{
    region:{
      type:String,
      default:'uk',
    },
  },
  computed: {
    ...mapGetters({
      regionClicked : 'cart/getRegionClicked'
    })
  },
}
